import { ReactComponent as CarIcon } from './icons/experiments-car.svg'
import { ReactComponent as BikeIcon } from './icons/experiments-bike.svg'
import { ReactComponent as StairsIcon } from './icons/experiments-stairs.svg'

const dataExperiments = [
  {
    href: 'https://gtsport.netlify.app/',
    title: 'GT Sport, 2021',
    desc: 'An in-browser recreation of a scene from the game Gran Turismo Sport. Great for learning Blender, namely lighting and camera movement, together with the React Three Fiber ecosystem.',
    Icon: CarIcon,
  },
  {
    href: 'https://rake.netlify.app/',
    title: 'Yerrgghhh, 2019',
    desc: 'A 3D physics recreation of Sideshow Bob\'s endless rake nightmare. Helped me better understand how React Three Fiber combines with the Cannon physics engine.',
    Icon: StairsIcon,
  },
  {
    href: 'https://emeraldhillzone.netlify.app/',
    title: 'Emerald Hill Zone, 2014',
    desc: 'A recreation of the level from the classic game Sonic the Hedgehog 2. This got me into CSS transforms and learning about browser performance & rendering.',
    Icon: BikeIcon,
  },
]

export default dataExperiments